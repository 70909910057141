:root {
  --color-first: #0d0d0f;
  --color-second: #242424;
  --color-third: #4e4e4e;

  --gold-color: #cdbeac;
  --selection-color: #14f195;
  --error-color: #f1141f;
}

html {
  background-color: var(--color-first);
}


.btn-simple {
  padding: 0;
  margin: 0;
  background: transparent;
  color: white;
  border: none;
  outline: none;
}

.btn-simple:disabled {
  opacity: .6;
}

.btn-default {
  background: var(--color-second);
  color: white;
  border: none;
}

.btn-default-reversed {
  background: white;
  color: black;
  border: none;
}

.btn-default-reversed:disabled {
  opacity: 0.8;
}

.btn-simple-fill,
.btn-empty-container {
  border: 1px solid white !important;
  color: white;
  background: transparent !important;
  transition: all .3s;
}

.btn-simple-fill:enabled:hover {
  color: black;
  background: white !important;
}

.btn-simple-fill-reversed {
  border: 1px solid black !important;
  color: black;
  background: transparent !important;
  transition: all .3s;
}

.btn-simple-fill-reversed:enabled:hover {
  color: white;
  background: black !important;
}

.btn-simple-fill:disabled,
.btn-simple-fill-reversed:disabled {
  opacity: 0.6;
}


.input-simple {
  border: none !important;
  box-shadow: none !important;
}

.input-simple:focus {
  outline: none !important;
}

.input-simple::selection {
  background: black;
  color: white;
}

.input-dark {
  background: black;
  color: white;
}

.hover-pointer:hover {
  cursor: pointer;
}

.hover-transparent,
.hover-gold {
  transition: all 0.2s;
}

.hover-transparent:hover {
  opacity: .8;
}

.hover-gold:hover {
  color: var(--gold-color) !important;
  opacity: .8;
}


.title {
  font-size: 4rem;
}

.sub-title {
  font-size: 2.5rem;
}

.text-luxury {
  font-family: "Cinzel Deco Reg";
}

.text-normal {
  font-family: "Perpetua";
}

.text-normal-base-size {
  font-family: "Perpetua";
  font-size: 1.2rem;
}

.text-bold {
  font-weight: 600;
}

.text-underline {
  text-decoration: underline;
}

.text-no-decoration {
  text-decoration: none;
}

.text-small {
  font-size: .75rem;
}

.text-selection,
.hover-selection-color:hover {
  color: var(--selection-color);
}

.text-error,
.hover-error-color:hover {
  color: var(--error-color);
}

.text-gold {
  color: var(--gold-color);
}

.text-gold-highlight {
  color: var(--gold-color);
  filter: drop-shadow(0px 0px 5px var(--gold-color));
}

.text-third {
  color: var(--color-third);
}

.text-letter-blocks {
  text-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgba(0, 0, 0, 0.9), 2px 2px 2px rgba(206, 89, 55, 0)
}


.bg-first {
  background: var(--color-first);
}


.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.bg-transparent {
  background: transparent;
}


.photobanner {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  z-index: 0;
  width: 100%;
  height: 100%;
}

#music-player {
  display: none
}

#music-controller {
  position: fixed;
  bottom: 10px;
  right: 10px;
  /* background: rgba(0, 0, 0, 0.6); */
  padding: .3rem;
  z-index: 1000;
}

#music-sound-range {
  width: 10px;
  height: 100px;
  /* background: #33ffaa; */
  /* border-radius: 2rem; */
  margin: auto;
  margin-bottom: 1rem;
}

#music-sound-range .range-slider {
  width: 10px;
  height: 100px;
  border-radius: 2rem;
  overflow: hidden;
}

#music-sound-range .range-slider .range-slider__thumb {
  display: none;
}

#music-sound-range .range-slider .range-slider__range {
  height: 100% !important;
}



#music-controller img {
  width: 50px;
  height: 50px;

  transition: all .3s;
}

#music-controller img:hover {
  transform: scale(1.1);
  opacity: .8;
}


.filler-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.loading-square {
  background: var(--color-second);
  height: 30px;
  margin: 0 auto;
  opacity: .85;
  border-radius: .2rem;
}

.elite-member-icon {
  color: #ffffff;
  filter: drop-shadow(0px 0px 5px #ffffff);
}


.eth-logo {
  width: 24px;
  height: auto;
}


.token-icon,
.pfp-icon {
  width: 20px;
  height: auto;
  border-radius: 50%;
}

.pfp-icon {
  width: 25px;
}


.divider {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  /* -webkit-box-shadow: 0px 0px 14px 1px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 14px 1px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 14px 1px rgba(255, 255, 255, 1); */
  filter: drop-shadow(0px 0px 5px white);
}

.divider.divider-vertical {
  width: 1px;
  height: auto;
}

.divider.divider-horizontal {
  height: 1px;
  width: auto;
}