#brokerage-account {
    position: relative;
    width: 100%;
    /* height: 100vh;
    overflow: hidden; */
}

#brokerage-account-welcome {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}

#brokerage-account-video {
    filter: opacity(0.6);
}

#brokerage-account-welcome-container {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
}

#brokerage-account-presentation-container {
    position: absolute;
    bottom: 5%;
    /* max-width: 35%; */
}


#brokerage-account-dashboard {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: var(--brokerage-background-color);
    color: black;
}

#brokerage-account-dashboard #brokerage-account-dashboard-nav button {
    color: var(--brokerage-primary-color);
}