.broker-card-info {
    position: relative;
    width: 200px;
    height: auto;
    background: var(--color-first);
    border-top-right-radius: .6rem;
    border-top-left-radius: .6rem;
    overflow: hidden;

    /* z-index: 100; */
    transition: all .4s;
}

.broker-card-info img {
    width: 100%;
    height: auto;
    filter: grayscale(.4);
}

.broker-card-info.searched,
.broker-card-info:not(.loading-card):hover {
    width: 250px;
    font-size: 1rem;
    /* cursor: pointer; */
}

.broker-card-info.not-searched img,
.broker-card-info.not-searched .points-info-container {
    opacity: .5;
    /* transition: all .4s; */
}

.broker-card-info:not(.loading-card):hover img,
.broker-card-info:not(.loading-card):hover .points-info-container {
    opacity: 1 !important;
}

.broker-card-info h5 {
    font-size: .9em;
    transition: inherit;
}

.broker-card-info.searched h5 {
    font-size: 1em;
    text-decoration: underline;
}


.broker-card-info:not(.searched):hover h5 {
    font-size: 1.2rem;
}


.broker-card-info .broker-level-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    color: white;
    border-radius: 50%;
    transform: translate(50%, -50%);
    z-index: 101;
}

.broker-card-info.searched .broker-level-icon,
.broker-card-info:hover .broker-level-icon {
    border: 1px solid white;
}