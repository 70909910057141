#main-handler {
    /* overflow-x: hidden; */
}

.loading-icon-anim {
    width: 20px;
    height: auto;
    margin: auto;
}

/* .toastify-transition {
    opacity: 0;
}

.toastify-transition.enter {
    -webkit-animation: fade-in 0.75s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 0.75s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    /* opacity: 1;
}

.toastify-transition.exit {
    -webkit-animation: fade-in 0.75s cubic-bezier(0.390, 0.575, 0.565, 1.000) reverse both;
    animation: fade-in 0.75s cubic-bezier(0.390, 0.575, 0.565, 1.000) reverse both;
    /* opacity: 0;
} */

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
}

.toastify-transition {
    animation-duration: 800ms;
}