#brokerage {
    width: 100%;
    min-height: 100%;
    background: var(--color-first);
    color: white;
    /* padding-bottom: 10rem; */
    /* padding-top: 1rem; */

    overflow-x: hidden;

    --brokerage-background-color: #f4ece2;
    --brokerage-primary-color: #b18502;
}

.broker-text-primary {
    color: var(--brokerage-primary-color);
}

.broker-icon {
    color: var(--brokerage-primary-color);
    filter: drop-shadow(0px 0px 5px var(--brokerage-primary-color));
}