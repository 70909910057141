#home {
    /* position: relative; */
    width: 100%;
    max-width: 100vw;
    min-height: 100%;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    background: var(--color-first);
}

#home-content {
    overflow-x: hidden;
}

#entry-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background: rgba(20, 20, 20, 0.8); */
    background: black;
    z-index: 10000;
    width: 100%;
    height: 100%;
    color: white;
    overflow: hidden;
}

#entry-container img {
    width: 300px;
    height: 300px;
}

#entry-container video {
    width: 100%;
    height: 100%;
}

#entry-container-main {
    width: 100%;
    /* margin-top: 5%; */
}

#entry-container-interact {
    position: absolute;
    bottom: 10px;
    width: 100%;
}

#entry-container-interact-info video {
    width: 50%;
    max-width: 300px;
    height: auto;
}

/* @media screen and (max-width: 768px) {
    #entry-container-main {
        margin-top: 4rem;
    }
} */

.parallax-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: unset !important;
    opacity: 0.5;
}

.parallax-container img {
    position: absolute;
    width: 125%;
    height: auto;
    min-height: 100vh;
    left: -9999px;
    right: -9999px;
    margin: auto;
    object-fit: cover;
}

#interior-restaurant-container {
    width: 100%;
    /* max-width: 100vw; */
    height: 100vh;
    /* overflow: hidden; */
}

#interior-restaurant-media {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: unset !important;
    background: var(--color-first);
    transform: translate(0, -15%);
}

#project-description {
    position: absolute;
    width: 450px;
    height: auto;
    top: 17.5%;
    left: 7.5%;
    color: white;
    font-size: 1.5rem;
    font-family: "Perpetua";
    pointer-events: none;
}

@media screen and (max-width: 1200px) {
    #project-description {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 992px) {
    #project-description {
        width: 350px;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 576px) {
    #project-description {
        width: unset;
        left: 7%;
        right: 7%;
        top: 15%;
        text-align: center;
        font-size: 1.1rem;
    }
}

#interior-restaurant-title-container {
    position: absolute;
    width: 100%;
    height: 25rem;
    top: 45%;
    pointer-events: none;
    /* text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5, -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5, -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5, -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5, 2px 2px 2px rgba(206, 89, 55, 0); */
    /* overflow: hidden; */
}

#interior-restaurant-title-container .interior-restaurant-title {
    position: absolute;
    color: white;
    font-size: 8rem;
    font-family: "Cinzel Deco Reg";
    font-weight: 700;
}

#interior-restaurant-title-container .interior-restaurant-title.first {
    right: 12.5%;
}

#interior-restaurant-title-container .interior-restaurant-title.second {
    right: 10%;
    top: 8.5rem;
}

#interior-restaurant-title-container .interior-restaurant-title.third {
    right: 15%;
    top: 17rem;
}

@media screen and (max-width: 1200px) {
    #interior-restaurant-title-container .interior-restaurant-title {
        font-size: 6rem;
    }

    #interior-restaurant-title-container .interior-restaurant-title.second {
        top: 25%;
    }

    #interior-restaurant-title-container .interior-restaurant-title.third {
        top: 50%;
    }
}

@media screen and (max-width: 768px) {
    #interior-restaurant-title-container .interior-restaurant-title {
        font-size: 5rem;
    }

    #interior-restaurant-title-container .interior-restaurant-title.second {
        top: 20%;
    }

    #interior-restaurant-title-container .interior-restaurant-title.third {
        top: 40%;
    }
}

@media screen and (max-width: 576px) {
    #interior-restaurant-title-container .interior-restaurant-title {
        font-size: 4rem;
        text-align: center;
    }

    #interior-restaurant-title-container .interior-restaurant-title.first,
    #interior-restaurant-title-container .interior-restaurant-title.second,
    #interior-restaurant-title-container .interior-restaurant-title.third {
        left: 0;
        right: 0;
    }

    #interior-restaurant-title-container .interior-restaurant-title.second {
        top: 17.5%;
    }

    #interior-restaurant-title-container .interior-restaurant-title.third {
        top: 35%;
    }
}


.transition-clouds-container {
    z-index: 1000;
    pointer-events: none;
}

#transition-clouds {
    position: relative;
    top: 0;
    width: 125%;
    height: auto;
    transform: translateX(-100px);
}

#main-building-container {
    position: relative;
    width: 100%;
    /* max-width: 100vw; */
    height: 0px;
    margin-top: 100px;
    padding-top: 100px;
    /* margin-top: -30%; */
    background: transparent;
    overflow: hidden;

}

#main-building-media>div {
    position: relative;
    width: 100%;
    height: 100%;
}

#main-building-media {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: unset !important;
    background: transparent;
    filter: drop-shadow(0px -3px 15px black);

    z-index: 0;
}

#tower-birds {
    position: absolute;
    top: 47.5%;
    left: 0;
    right: 0;
    width: 100%;
}

.tower-bottom-image-container {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
}

.tower-bottom-image-container>img {
    min-height: unset;
}

#main-building-content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    color: white;
}

#main-building-content .content-container {
    position: absolute;
    max-width: 100%;
    max-width: 90% !important;
}

#main-building-content .content-container img {
    width: 100%;
    height: auto;
}

/* #main-building-content .content-container.scroll-indicator {
    top: 5%;
    left: 0;
    right: 0;
    width: 100%;
    max-width: unset !important;
    color: #000000;
} */

#main-building-content .content-container.penthouse-level {
    top: 3.92%;
    left: 20px;
}

#main-building-content .content-container.offices-level {
    top: 24.48%;
    right: 20px;
    left: unset !important;
    text-align: right;
}

#main-building-content .content-container.grove-level {
    top: 50%;
    left: 20px;
}

#main-building-content .content-container.entrance-level {
    top: 72%;
    right: 20px;
    left: unset !important;
    text-align: right;
}





.brokerage-program-info-card {
    position: relative;
    /*var(--color-second);*/
    min-height: 400px;
    height: 1px;
    max-width: 300px;
}

.brokerage-program-info-card.first .content.front,
.brokerage-program-info-card.second .content.back {
    border-radius: 1rem 0;
    border-top: 5px double white;
    border-right: 5px double white;
}

.brokerage-program-info-card.second .content.front,
.brokerage-program-info-card.first .content.back {
    border-radius: 0 1rem;
    border-top: 5px double white;
    border-left: 5px double white;
}

.brokerage-program-info-card .content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #cccccc;

    display: flex;
    justify-content: center;
    align-items: center;
}

.brokerage-program-info-card .content.front {
    position: relative;
}

.brokerage-program-info-card .content.front h1 {
    color: transparent;
    background: #444444;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.5);
}




.arrow {
    margin: auto;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate3d(0px, 0px, 0px) skew(-1deg, 1deg);
}

.arrow-container {
    perspective: 113px;
    perspective-origin: 51% -150%;
}

.arrow>img {
    position: absolute;
    box-sizing: border-box;
    /* padding: 10px; */
    line-height: 200px;
    text-align: center;
    height: 100%;
    width: 100%;
    transform: translate3d(1027px, -1px, -105px) rotateX(118deg) rotateZ(90deg) scale(20) rotateY(-65deg);
    transform-origin: top center;
}