#brokerage-home {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}

#brokerage-home-video {
    filter: opacity(0.6) blur(5px) grayscale(1);
}

#brokerage-emblem-container {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
}

#brokerage-enter-container {
    position: absolute;
    bottom: 10%;
}