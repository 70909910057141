#navbar {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 1rem;
    z-index: 1000;
    background: rgba(13, 13, 15, 1);
}

#navbar.transparent,
#navbar.semi-transparent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 1rem;
    background: rgba(13, 13, 15, 0);
}

@media screen and (max-width: 998px) {
    #navbar {
        padding-top: 0;
    }
}

#navbar .logo-img,
#navbar-overlay .logo-img {
    width: 65px;
    height: 65px;
}

#navbar-offcanvas-toggle,
.navbar-socials-container {
    z-index: 1;
}

#navbar-offcanvas-toggle #menu-toggle-button {
    width: 50px;
    min-width: 50px;
    height: 50px;
}

@media screen and (min-width: 768px) {
    #navbar-offcanvas-toggle {
        border-right: 1px solid rgb(205 190 172 / 30%);
    }

    .navbar-socials-container {
        border-left: 1px solid rgb(205 190 172 / 30%);
    }

    #navbar-offcanvas-toggle #menu-toggle-button {
        width: 60px;
        min-width: 60px;
        height: 60px;
    }
}

#navbar-overlay {
    background: var(--color-first);
}

#navbar-overlay .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

#navbar-overlay .btn-close:focus {
    box-shadow: none;
}

.navbar-option {
    background: transparent;
}

.navbar-option.selected * {
    color: #cdbeac;
    filter: drop-shadow(0px 0px 5px #cdbeac);
    /* text-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgba(0, 0, 0, 0.9), 2px 2px 2px rgba(206, 89, 55, 0); */
}