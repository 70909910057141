#brokerage-join {
    padding-top: 3rem;
    padding-bottom: 5rem;
}

#create-account-btn {
    min-width: 260px;
    min-height: 50px;
    background: transparent;
    color: white;
    border: 1px solid white;

    transition: all .4s;
}

#create-account-btn:disabled {
    opacity: .4;
}

#create-account-btn:enabled:hover,
#create-account-btn.loading {
    /* opacity: .8; */
    background: white;
    color: black;
}