#brokerage-account-profile {
    color: black;
    /* padding: 0px 4rem; */
}

.btn-simple-fill.invite-broker-btn {
    transition: all 0s !important;
}

.btn-simple-fill.invite-broker-btn:hover img,
.btn-simple-fill-reversed.invite-broker-btn img {
    filter: invert(1);
    transition: all 0s;
}

.btn-simple-fill.invite-broker-btn img,
.btn-simple-fill-reversed.invite-broker-btn:hover img {
    filter: invert(0);
    transition: all 0s;
}

#broker-pfp-img {
    width: 250px;
    height: 250px;
    border-radius: 7px;
    filter: drop-shadow(0px 0px 5px grey);
}

#broker-card-canvas {
    max-width: 100%;
    filter: drop-shadow(0px 4px 6px black);
}