.view-account-btn {
    width: 38px;
}

.connection-button {
    min-width: 180px;
    min-height: 36px;
    display: flex;
    align-items: center;
    color: black;
    background: white;
    transition: all .3s;
}

.connection-button:hover,
.connection-button.loading {
    opacity: .8;
    color: black;
    background: white;
}

.connection-button:hover button,
.connection-button.loading button {
    color: black;
}

.connection-button .loading-icon-anim {
    width: 20px;
    height: auto;
    margin: auto;
}

.connection-button button {
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
    padding: .3rem .5rem;

    transition: all .3s;
}

.connection-button.btn-fill button {
    background: white;
    color: black;
}