.art-content-media {
    position: relative;
    filter: grayscale(1) contrast(1.1);
    transition: all .3s;
}

.art-content-media:hover {
    filter: none;
}

.art-content-media .art-content-media-download-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
    /* height: 50px; */
    background: transparent;
    padding: 0.2rem;
}

.art-content-media.gm-media-container .art-content-media-download-btn {
    bottom: 25px;
    right: 30px;
}

.art-content-media-download-btn:hover {
    cursor: pointer;
}

.art-content-media-download-btn svg {
    width: 100%;
    height: auto;
}

.art-content-media-download-btn svg path {
    transition: all .3s ease-out;
}

.art-content-media-download-btn:hover svg path {
    transform: translateY(50px)
}

.art-content-media>*:first-child {
    width: 100%;
    height: auto;
    /* padding: 1rem; */
    /* background: white; */
}


@media screen and (max-width: 1200px) {
    .art-content-media>*:first-child {
        /* padding: .7rem; */
    }

    .art-content-media .art-content-media-download-btn {
        width: 27.5px;
        bottom: 7.5px;
        right: 7.5px;
    }

    .art-content-media.gm-media-container .art-content-media-download-btn {
        bottom: 20px;
        right: 22px;
    }
}

@media screen and (max-width: 998px) {
    .art-content-media>*:first-child {
        /* padding: .5rem; */
    }

    .art-content-media .art-content-media-download-btn {
        width: 25px;
        bottom: 5px;
        right: 5px;
    }
}

@media screen and (max-width: 768px) {
    .art-content-media>*:first-child {
        /* padding: .4rem; */
    }

    .art-content-media .art-content-media-download-btn {
        width: 22.5px;
        bottom: 2.5px;
        right: 2.5px;
    }

    .art-content-media.gm-media-container .art-content-media-download-btn {
        bottom: 10px;
        right: 10px;
    }
}

@media screen and (max-width: 576px) {
    .art-content-media>*:first-child {
        /* padding: .3rem; */
    }

    .art-content-media .art-content-media-download-btn {
        width: 20px;
        bottom: 0px;
        right: 0px;
    }

    .art-content-media.gm-media-container .art-content-media-download-btn {
        bottom: 5px;
        right: 7px;
    }
}