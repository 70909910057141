#art-content {
    width: 100%;
    min-height: 100%;
    background: var(--color-first);
}

#art-content-memes,
#art-content-gms,
#art-content-illustrations {
    width: 100%;
    flex-wrap: wrap;
    /* background: white; */

    margin-top: 10rem;
}

.gm-media-container .gm-date-text {
    position: absolute;
    top: 1.1rem;
    left: 1.25rem;
}

@media screen and (max-width: 768px) {
    .gm-media-container .gm-date-text {
        top: 10px;
        left: 15px;
    }
}

@media screen and (max-width: 576px) {
    .gm-media-container .gm-date-text {
        top: 5px;
        left: 10px;
    }
}



#art-content-memes .meme-media-container.container-3 {
    transform: translateY(50px);
    z-index: 2;
}

#art-content-memes .meme-media-container.container-4 {
    transform: translateY(-50px) translateX(30px);
    z-index: 2;
}

#art-content-memes .meme-media-container.container-7 {
    transform: translate(0, 120px);
    z-index: 2;
}

#art-content-memes .meme-media-container.container-8 {
    z-index: 2;
}

#art-content-memes .meme-media-container.container-9 {
    transform: translate(-10px, -15px);
    z-index: 3;
}

#art-content-memes .meme-media-container.container-11 {
    transform: translate(-20px, 30px);
    z-index: 2;
}

#art-content-memes .meme-media-container.container-12 {
    transform: translate(10px, -20px);
    z-index: 2;
}

#art-content-memes .meme-media-container.container-14 {
    transform: translate(0px, 30px);
}

@media screen and (max-width: 998px) {
    #art-content-memes .meme-media-container.container-3 {
        transform: translateY(40px);
    }

    #art-content-memes .meme-media-container.container-4 {
        transform: translateY(-40px) translateX(20px);
    }

    #art-content-memes .meme-media-container.container-7 {
        transform: translate(0, 90px);
    }

    #art-content-memes .meme-media-container.container-9 {
        transform: translate(-7.5px, -10px);
    }

    #art-content-memes .meme-media-container.container-11 {
        transform: translate(-10px, 15px);
    }

    #art-content-memes .meme-media-container.container-14 {
        transform: translate(0px, 15px);
    }
}

@media screen and (max-width: 768px) {
    #art-content-memes .meme-media-container.container-3 {
        transform: translateY(30px);
    }

    #art-content-memes .meme-media-container.container-4 {
        transform: translateY(-30px) translateX(15px);
    }

    #art-content-memes .meme-media-container.container-7 {
        transform: translate(0, 60px);
    }

    #art-content-memes .meme-media-container.container-9 {
        transform: translate(-5px, -7.5px);
    }

    #art-content-memes .meme-media-container.container-11 {
        transform: translate(-5px, 10px);
    }

    #art-content-memes .meme-media-container.container-14 {
        transform: translate(0px, 10px);
    }
}

@media screen and (max-width: 576px) {
    #art-content-memes .meme-media-container.container-3 {
        transform: translateY(20px);
    }

    #art-content-memes .meme-media-container.container-4 {
        transform: translateY(-20px) translateX(10px);
    }

    #art-content-memes .meme-media-container.container-7 {
        transform: translate(0, 30px);
    }

    #art-content-memes .meme-media-container.container-9 {
        transform: translate(-2.5px, -5px);
    }

    #art-content-memes .meme-media-container.container-11 {
        transform: translate(-2px, 5px);
    }

    #art-content-memes .meme-media-container.container-14 {
        transform: translate(0px, 5px);
    }
}