#brokerage-account-points #project-twitter-timeline-container>div {
    width: 100%;
}

#tweet-gm-btn img {
    filter: invert(0);
    transition: all .3s;
}

#tweet-gm-btn:hover img {
    filter: invert(1);
}

#analyze-tweets-btn {
    width: 175px;
}